import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled, up, css, thd } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';
import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import BgSecondary from 'components/CustomComponents/Backgrounds/BgSecondary';
import bannerDesktop from '../../theme/Banners/Welcome-Hero@1x.jpg';
import bannerMobile from '../../theme/Banners/Welcome-Hero-mobile@1x.png';
import bannerDesktop2x from '../../theme/Banners/Welcome-Hero@2x.jpg';
import bannerMobile2x from '../../theme/Banners/Welcome-Hero-mobile@2x.png';
import Spend from './images/Spend@2x.png';
import Earn from './images/Earn@2x.png';
import Redeem from './images/Redeem@2x.png';
import Banner from 'components/CustomComponents/Banners/Banner';
import LoadAsync from '_platform/src/utils/LoadAsync';

const RewardsProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "rewardsProvider" */ '../RewardsProvider/RewardsProvider'
  )
);

const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);

const StatementSummary = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementLoyalty" */ '../../components/StatementLoyalty/StatementSummary'
  )
);

const PageContainer = styled.div`
  .math-symbols {
    ${up(
      'md',
      css`
        padding: 65px 0;
      `
    )};
  }

  .spend {
    background-color: #f68827;
  }

  .earn {
    background-color: #f36f28;
  }

  .redeem {
    background-color: #ef492b;
  }

  .rewards-button {
    color: #fff;
    font-weight: bold;
    padding: 0.5rem 3rem;
  }

  .rewards-list-item-content {
    border: 1px solid ${thd('secondary', '#fff')};
  }

  .browse--button {
    font-weight: bold;
    padding: 0.5rem 3rem;
    color: #fff;
  }

  .category-container {
    display: block;
    padding: 0;
    margin: 0;
  }

  .category-image {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .category-name {
    margin-top: 0;
    background-color: ${thd('secondary', '#fff')};
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 0.35rem 0;
    display: inline-block;
    width: 100%;
  }

  .statement-container {
    padding: 40px 0;
    background-color: #ececf1;

    .statement-summary-header {
      display: none;
    }
  }
`;

const HomePage = ({ settings }) => {
  return (
    <PageContainer>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <Banner
          pageTitle={<React.Fragment>Welcome to MAX Rewards</React.Fragment>}
          bannerMobile={bannerMobile}
          bannerMobile2x={bannerMobile2x}
          bannerTablet={bannerDesktop}
          bannerTablet2x={bannerDesktop2x}
          bannerDesktop={bannerDesktop}
          bannerDesktop2x={bannerDesktop2x}
        />
        <BgSecondary>
          <Grid>
            <Row justifyContent="center">
              <Col className="text--center">
                <h2>MAXimise your membership and redeem your rewards here</h2>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer--bottom--large">
              <Col
                xs={12}
                md={3}
                className="text--center spend text--secondary spacer--around--small spacer--top"
              >
                <img src={Spend} alt="Spend" height={88} />
                <h2>Spend</h2>
                <p className="text--bold">
                  Make eligible purchases from your preferred suppliers on your
                  Trade Member Direct account.
                </p>
              </Col>
              <Col xs={12} md={1} className="text--center">
                <h1 className="text--primary vertical math-symbols">+</h1>
              </Col>
              <Col
                xs={12}
                md={3}
                className="text--center earn text--secondary spacer--around--small spacer--top"
              >
                <img src={Earn} alt="Earn" height={88} />
                <h2>Earn</h2>
                <p className="text--bold">
                  Every $1 spent = 1.5 MAX rewards points. Members can earn
                  Bonus MAX points with exclusive preferred supplier promotions.
                </p>
              </Col>
              <Col xs={12} md={1} className="text--center">
                <h1 className="text--primary vertical math-symbols">=</h1>
              </Col>
              <Col
                xs={12}
                md={3}
                className="text--center redeem text--secondary spacer--around--small spacer--top"
              >
                <img src={Redeem} alt="Redeem" height={88} />
                <h2>Redeem</h2>
                <p className="text--bold">
                  Redeem your points from over a hundred products in the
                  catalogue
                </p>
              </Col>
            </Row>
          </Grid>
        </BgSecondary>
        <RewardsProvider
          showFeatured
          rewardLabels={{ highlightHeading: 'Discover & Redeem' }}
        />
        <section className="statement-container">
          <h3 className="text--center">My Points Dashboard</h3>
          <StatementProvider
            fetch={[
              {
                endpoint: '/Statement/v1/Loyalty/Stealth',
                section: 'loyalty',
              },
            ]}
            render={({ statement }) =>
              !statement || !statement.data ? (
                <LoadingAnimation /> // Loader instead of `null`?
              ) : (
                <StatementSummary
                  statementHideClaimDetails
                  statement={statement.data.loyalty}
                />
              )
            }
          />
        </section>
      </PrivateComponent>
    </PageContainer>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
